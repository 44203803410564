import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { Link } from "gatsby";

function NotFoundPage() {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <div className="mx-auto bg-black text-white">
        <div className="p-8 py-16 md:p-16">
          <div>
            <h1 className="mb-3 mt-4 text-4xl font-semibold leading-none">
              Page not found
            </h1>
            <p>
              Alas, this is going nowhere. Head back to the{" "}
              <Link to="/">main page</Link>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default NotFoundPage;
